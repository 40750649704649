@import "../../_globalColor";

.contact-div-main {
  display: flex;
}

.dark-mode a {
  color: $textColorDark;
}

.contact-div-main > * {
  flex: 1;
}

.contact-header {
  font-size: 45px;
  font-weight: 200;
}

.contact-line,
.dark-mode-contact-line{
  color: #868e96;
  margin-top: 20px;
  font-size: 20px;
  font-weight: 400;
  text-decoration: none;
  text-transform: uppercase;
}

.contact-detail:hover,
.contact-detail-email:hover {
  color: $textColor;
  text-shadow: 2px 1px 2px $contactDetailHoverTS;
  transition: all 0.3s;
}

.contact-subtitle {
  text-transform: uppercase;
}

.contact-des-div{
  margin-top: 1.5rem;
  font-weight: bold;
  font-size: 25px;
}

.contact-email-div {
  margin-top: 1.5rem;
}

.contact-location-div {
  margin-top: 0.5rem;
}

.contact-margin-top {
  margin-top: 4rem;
}

.contact-image-div{
  flex:40%;
}

.contact-text{
  flex:60%;
}

.lottie-animation{
  width: 10px;
  height: 10px;
  // margin-left: 1.5rem;
  // margin-top: -4rem;
}

/* Media Query */
@media (max-width: 1380px){
  .contact-div-main {
    display: flex;
  }
  .contact-div-main > * {
    flex: 1;
  }
  .greeting-image-div {
    height: 290px;
    margin-top: 20px;
    margin-bottom: 0;
  }
  .contact-title {
    font-size: 56px;
    font-weight: 400;
    text-align: center;
  }

  .contact-title {
    font-size: 40px;
    text-align: center;
  }

  .contact-subtitle {
    font-size: 16px;
    line-height: normal;
    text-align: center;
  }

  .contact-detail,
  .contact-detail-email {
    font-size: 20px;
  }
  .contact-des-div {
    margin-top: 1.5rem;
  }
  .contact-email-div {
    margin-top: 1.5rem;
  }
}
@media (max-width: 768px) {
  .contact-image-div {
    height: 100px;
    margin-top: 20px;
    margin-bottom: 10;
  }
}
@media (min-width: 320px) and (max-width: 768px){
  .main {
    width: auto;
  }
  .contact-div-main {
    flex-direction: column;
    text-align: center;
    margin-top: 70px;
    
  }
  .contact-image-div{
    text-align: center;
  }
  .contact-image-div{
    display: none;
  }
}
